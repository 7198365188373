import React, { useMemo } from "react";
import { Controller, type ControllerRenderProps, useForm } from "react-hook-form";

import {
  type AutocompleteRenderGetTagProps,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { head, isEmpty, isNil, map, not, prop, values } from "ramda";
import * as yup from "yup";
import { type InferType } from "yup";

import {
  ASSIGNED_BADGES,
  INTERESTS_NAMES,
  PERMISSION_BADGE_DEPENDENCY,
  USER_BADGES,
  USER_BADGE_TYPES,
  USER_PERMISSIONS,
} from "../helpers/const";
import { Sizing, Spacing } from "../types/enum";
import {
  EventPermissions,
  GetUserAdminDocument,
  Interest,
  UserBadge,
  type UserBadgeType,
  type UserModel,
  useAdminEditUserMutation,
  useAdminGetOccupationsQuery,
  useDeleteUserBadgeMutation,
} from "../types/graphql";

import { GET_USER_ASSESSMENT_RESULTS } from "src/gql/queries/user";
import {
  SpeakingLevelCategoriesText,
  SpeakingLevelScoreMax,
  SpeakingLevelScoreMin,
  SpeakingLevelScoreRangesStartsValues,
  getSpeakingLevelCategory,
} from "src/helpers/speakingLevel";
import Autocomplete from "../components/Autocomplete";
import ConfirmationModal from "../components/ConfirmationModal";
import { useOnOffSwitch } from "../helpers/hooks";
import { type AutocompleteOptionModel } from "../types/generic";
import AssignBadgeCont from "./AssignBadgeCont";

/**
 * Types
 */
interface Props {
  user: UserModel;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  story: yup.string().nullable(),
  birthday: yup.string().nullable(),
  createdAt: yup.string().required(),
  country: yup.string().nullable(),
  city: yup.string().nullable(),
  kind: yup.mixed<EventPermissions>().oneOf(Object.values(EventPermissions)).required(),
  speakingLevelScore: yup.number().min(SpeakingLevelScoreMin).max(SpeakingLevelScoreMax).required(),
  nationality: yup.string().nullable(),
  nativeLanguage: yup.string().nullable(),
  otherLanguage: yup.string().nullable(),
  needSpeakingPartner: yup.bool().required(),
  showBirthday: yup.bool().required(),
  badge: yup.mixed<UserBadge>().oneOf(Object.values(UserBadge)).required(),
  isAgeVisible: yup.bool().required(),
  isActive: yup.bool().required(),
  isVerified: yup.bool().required(),
  isFeatured: yup.bool().required(),
  wantsNewsletter: yup.bool().required(),
  interests: yup.array().required(),
  occupation: yup
    .object()
    .shape({ id: yup.string().required(), name: yup.string().required() })
    .nullable(),
  pauseCredits: yup.number().required(),
  badges: yup.array(),
});
type FormValues = InferType<typeof schema>;

/**
 * Helpers
 */
const humanizeInterests = (interest: Interest) => INTERESTS_NAMES.get(interest);
const getInterestsOptions = (interest: Interest) => ({
  id: interest,
  title: humanizeInterests(interest),
});

/**
 * Constants
 */
const RENDER_LABEL_ABOVE_INPUT = true;
const INTERESTS_OPTIONS = values(Interest).map(getInterestsOptions);

const UserEditPageFormCont: React.FC<Props> = ({ user }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, open, close] = useOnOffSwitch();
  const [isAssignOpen, openAssign, closeAssign] = useOnOffSwitch();
  const [badgeType, setBadgeType] = React.useState<keyof typeof UserBadgeType | null>(null);

  const { data } = useAdminGetOccupationsQuery({
    fetchPolicy: "cache-and-network",
  });

  const [editUser, { loading }] = useAdminEditUserMutation({
    refetchQueries: [
      { query: GetUserAdminDocument, variables: { id: user?.id } },
      { query: GET_USER_ASSESSMENT_RESULTS, variables: { userId: user?.id, filters: {} } },
    ],
  });

  const [deleteBadge] = useDeleteUserBadgeMutation({
    refetchQueries: [{ query: GetUserAdminDocument, variables: { id: user?.id } }],
  });

  const onDeleteBadge = async () => {
    if (badgeType) {
      const variables = {
        input: {
          userId: user?.id,
          type: badgeType,
        },
      };

      try {
        await deleteBadge({ variables });
        enqueueSnackbar("Badge successfully deleted", { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        close();
      }
    }
  };

  const occupationOptions = data?.adminGetOccupations
    ? data?.adminGetOccupations.map((occupation) => ({
        id: occupation?.id,
        name: occupation?.name,
      }))
    : [];

  const assignedByAdminOptions = ASSIGNED_BADGES.map((badge) => ({
    id: badge,
    name: USER_BADGE_TYPES.get(badge),
  }));

  const defaultValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    interests: map(getInterestsOptions, user.interests ?? []),
    occupation: user.occupation,
    country: user.country,
    city: user.city,
    kind: user.kind,
    speakingLevelScore: user.speakingLevelScore,
    showBirthday: user.showBirthday,
    birthday:
      isNil(user.birthday) || isEmpty(user.birthday)
        ? undefined
        : format(user.birthday, "yyyy-MM-dd"),
    createdAt:
      isNil(user.createdAt) || isEmpty(user.createdAt)
        ? undefined
        : format(user.createdAt, "yyyy-MM-dd"),
    needSpeakingPartner: user.needSpeakingPartner,
    badge: user.badge,
    isActive: user.isActive,
    isFeatured: user.isFeatured,
    isVerified: user.isVerified,
    isAgeVisible: user.isAgeVisible,
    nationality: user.nationality,
    nativeLanguage: user.nativeLanguage,
    story: user.story,
    otherLanguage: user.otherLanguage,
    wantsNewsletter: user.wantsNewsletter,
    pauseCredits: user.pauseCredits,
    badges: user.badges?.map((badge) => ({
      id: badge.type,
      name: USER_BADGE_TYPES.get(badge.type),
    })),
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, errors },
    watch,
  } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    // @ts-expect-error graphql types should not be null
    defaultValues,
  });

  const inputLabelProps = useMemo(() => {
    return { shrink: RENDER_LABEL_ABOVE_INPUT };
  }, []);

  const renderers = {
    interests: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "interests"> }) => {
      return (
        <Autocomplete
          multiple
          label="Interests"
          options={INTERESTS_OPTIONS}
          value={value}
          onChange={onChange}
        />
      );
    },
    occupation: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "occupation"> }) => {
      return (
        <Autocomplete
          label="Occupation"
          options={occupationOptions as AutocompleteOptionModel[]}
          value={value}
          onChange={onChange}
        />
      );
    },
    speakingLevelScore: ({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, "speakingLevelScore">;
    }) => {
      return (
        <TextField
          label="Speaking Level"
          select
          value={value}
          variant="outlined"
          onChange={({ target }) => {
            onChange(target.value);
          }}
        >
          <MenuItem value={value} key={`user-score-${value}`}>
            {value} {SpeakingLevelCategoriesText[getSpeakingLevelCategory(value)]}
          </MenuItem>
          {SpeakingLevelScoreRangesStartsValues.map((option) => (
            <MenuItem value={option} key={`option-score-${option}`}>
              {option} {SpeakingLevelCategoriesText[getSpeakingLevelCategory(option)]}
            </MenuItem>
          ))}
        </TextField>
      );
    },
    switch: <TName extends keyof FormValues>({
      field: { value, onChange },
    }: {
      field: ControllerRenderProps<FormValues, TName>;
    }) => (
      <Switch
        checked={!!value}
        edge="start"
        color="secondary"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          onChange(target.checked);
        }}
      />
    ),
    badge: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "badge"> }) => {
      const selectedKind = watch("kind");
      const listOfBadges = selectedKind ?? user.kind;
      const badges = PERMISSION_BADGE_DEPENDENCY.get(listOfBadges) ?? [];
      return (
        <Select
          value={value}
          variant="outlined"
          onChange={({ target }) => {
            onChange(target.value);
          }}
        >
          {Object.values(badges).map((value) => (
            <MenuItem value={value} key={value}>
              {USER_BADGES.get(value)}
            </MenuItem>
          ))}
        </Select>
      );
    },
    kind: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "kind"> }) => {
      const possiblePermissions = [
        EventPermissions.InactiveMember,
        EventPermissions.ZeroToOne,
        EventPermissions.OneToThree,
        EventPermissions.ThreeToTwelve,
        EventPermissions.MoreThanTwelveMonths,
        EventPermissions.BigSister,
        EventPermissions.HeyLadyTeam,
        EventPermissions.HeyLadyAdmin,
      ];
      return (
        <Select
          value={value}
          variant="outlined"
          onChange={({ target }) => {
            onChange(target.value);
            setValue(
              "badge",
              // @ts-expect-error rewrite this
              head(PERMISSION_BADGE_DEPENDENCY.get(target.value as EventPermissions)) ?? null,
            );
          }}
        >
          {Object.values(possiblePermissions).map((value) => (
            <MenuItem value={value} key={value}>
              {USER_PERMISSIONS.get(value)}
            </MenuItem>
          ))}
        </Select>
      );
    },
    pauseCredits: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "pauseCredits"> }) => {
      return (
        <TextField
          type="number"
          label="Pause Credits"
          variant="outlined"
          value={value}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(Number.parseInt(target.value));
          }}
        />
      );
    },
    tags: (value: AutocompleteOptionModel[], getTagProps: AutocompleteRenderGetTagProps) => {
      return value.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          key={option.id}
          label={option.name}
          disabled={!ASSIGNED_BADGES.includes(option.id as UserBadgeType)}
          onDelete={() => {
            setBadgeType(option.id as keyof typeof UserBadgeType);
            open();
          }}
        />
      ));
    },
    badges: () => {
      const options = assignedByAdminOptions.filter(
        (option) => !user.badges?.some((badge) => badge.type === option.id),
      );
      return (
        <Autocomplete
          multiple
          label="Badges"
          options={options as AutocompleteOptionModel[]}
          value={user.badges?.map((badge) => ({
            id: badge.type,
            name: USER_BADGE_TYPES.get(badge.type),
          }))}
          onChange={(value) => {
            handleBadgeChange(value);
          }}
          renderTags={renderers.tags}
        />
      );
    },
  };

  const handleBadgeChange = (value: AutocompleteOptionModel | AutocompleteOptionModel[] | null) => {
    const selectedOption = Array.isArray(value)
      ? value.find((option) => !user.badges.some((badge) => badge.type === option.id))
      : value;

    if (selectedOption) {
      setBadgeType(selectedOption.id as keyof typeof UserBadgeType);
    }

    openAssign();
  };

  const onSubmit = async (form: any): Promise<void> => {
    const values = getValues();

    const variables = {
      id: user?.id,
      input: {
        firstName: form?.firstName,
        lastName: form?.lastName,
        email: form?.email,
        story: form?.story,
        birthday: form?.birthday ? form?.birthday : undefined,
        // createdAt: form?.createdAt ? form?.createdAt : undefined,
        badge: form?.badge ?? values.badge,
        country: form?.country,
        city: form?.city,
        speakingLevelScore: form?.speakingLevelScore,
        nativeLanguage: form?.nativeLanguage,
        nationality: form?.nationality,
        otherLanguage: form?.otherLanguage,
        occupationId: form?.occupation?.id,
        needSpeakingPartner: form?.needSpeakingPartner,
        showBirthday: form?.showBirthday,
        isAgeVisible: form?.isAgeVisible,
        isFeatured: form?.isFeatured,
        isVerified: form?.isVerified,
        isVisible: form?.isVisible,
        isActive: form?.isActive,
        wantsNewsletter: form?.wantsNewsletter,
        interests: map(prop("id"), form?.interests ?? []) as Interest[],
        kind: form?.kind,
        pauseCredits: form?.pauseCredits,
      },
    };
    try {
      await editUser({ variables });
      enqueueSnackbar("User was updated successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem updating user", { variant: "error" });
    }
  };

  const handleCloseAssignmentModal = () => {
    setBadgeType(null);
    closeAssign();
  };

  return (
    <>
      <Card>
        {loading ? <LinearProgress /> : null}

        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid spacing={Spacing.m} container>
              <Grid item xs={Sizing.OneThird}>
                <FormControl fullWidth>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    {...register("firstName")}
                    error={not(isNil(errors?.firstName))}
                    helperText={
                      not(isNil(errors?.firstName)) ? "This field is required" : undefined
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.OneThird}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    {...register("lastName")}
                    error={not(isNil(errors?.lastName))}
                    helperText={not(isNil(errors?.lastName)) ? "This field is required" : undefined}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.OneThird}>
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    variant="outlined"
                    {...register("email")}
                    error={not(isNil(errors?.email))}
                    helperText={not(isNil(errors?.email)) ? "This field is required" : undefined}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <TextField
                    label="City"
                    variant="outlined"
                    {...register("city")}
                    error={not(isNil(errors?.city))}
                    helperText={not(isNil(errors?.city)) ? "This field is required" : undefined}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <TextField
                    label="Country"
                    variant="outlined"
                    {...register("country")}
                    error={not(isNil(errors?.country))}
                    helperText={not(isNil(errors?.country)) ? "This field is required" : undefined}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <TextField
                    type="date"
                    label="Birthday"
                    InputLabelProps={inputLabelProps}
                    variant="outlined"
                    {...register("birthday")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <Controller
                    name="pauseCredits"
                    control={control}
                    render={renderers.pauseCredits}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <TextField label="Nationality" variant="outlined" {...register("nationality")} />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <TextField
                    label="Native Language"
                    variant="outlined"
                    {...register("nativeLanguage")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <TextField
                    label="Other Language"
                    variant="outlined"
                    {...register("otherLanguage")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <Controller
                    name="speakingLevelScore"
                    control={control}
                    render={renderers.speakingLevelScore}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <Controller name="interests" control={control} render={renderers.interests} />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <TextField
                    type="date"
                    label="Created at"
                    InputLabelProps={inputLabelProps}
                    variant="outlined"
                    {...register("createdAt")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <Controller name="occupation" control={control} render={renderers.occupation} />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <Controller name="badges" control={control} render={renderers.badges} />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Full}>
                <FormControl fullWidth>
                  <TextField multiline label="Story" variant="outlined" {...register("story")} />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <Box mb={Spacing.s}>
                    <FormLabel>Community role</FormLabel>
                  </Box>
                  <Controller name="badge" control={control} render={renderers.badge} />
                </FormControl>
              </Grid>

              <Grid item xs={Sizing.Half}>
                <FormControl fullWidth>
                  <Box mb={Spacing.s}>
                    <FormLabel>Permission</FormLabel>
                  </Box>
                  <Controller name="kind" control={control} render={renderers.kind} />
                </FormControl>
              </Grid>

              {/* <Grid item xs={Sizing.OneFourth}>
              <Typography variant="h5" color="textPrimary">
                Email Verified
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This will make the user able to onboard
              </Typography>
              <Controller name="wantsNewsletter" control={control} render={renderers.switch} />
            </Grid> */}

              <Grid item xs={Sizing.OneThird}>
                <Typography variant="h5" color="textPrimary">
                  Verified Email/Active account
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  This will activate the user's account
                </Typography>
                <Controller name="isActive" control={control} render={renderers.switch} />
              </Grid>

              <Grid item xs={Sizing.Half}>
                <Typography variant="h5" color="textPrimary">
                  Verified By Staff
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  This will mark user as verified
                </Typography>
                <Controller name="isVerified" control={control} render={renderers.switch} />
              </Grid>

              <Grid item xs={Sizing.OneFourth}>
                <Typography variant="h5" color="textPrimary">
                  Age Visible
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  This will make user's age visible
                </Typography>
                <Controller name="isAgeVisible" control={control} render={renderers.switch} />
              </Grid>

              <Grid item xs={Sizing.OneThird}>
                <Typography variant="h5" color="textPrimary">
                  Need Speaking Partner
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  This will be visible on user's account profile
                </Typography>
                <Controller
                  name="needSpeakingPartner"
                  control={control}
                  render={renderers.switch}
                />
              </Grid>

              <Grid item xs={Sizing.Full}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={loading || not(isValid)}
                  fullWidth
                >
                  Update profile
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <ConfirmationModal
        open={isOpen}
        onSubmit={onDeleteBadge}
        onClose={close}
        title="Are you sure you want to delete this badge?"
        description="This can not be undone!"
      />

      {badgeType ? (
        <AssignBadgeCont
          open={isAssignOpen}
          onClose={handleCloseAssignmentModal}
          badgeType={badgeType}
          userId={user.id}
        />
      ) : null}
    </>
  );
};

export default UserEditPageFormCont;
