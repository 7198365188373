import { SpeakingLevelCategories } from "src/types/graphql";

export const SpeakingLevelScoreMin = 0;
export const SpeakingLevelScoreMax = 10;

export const SpeakingLevelScoreFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export enum SpeakingLevelCategoriesText {
  Dependent = "Dependent",
  IntermediateLow = "Intermediate Low",
  IntermediateMid = "Intermediate Mid",
  IntermediateHigh = "Intermediate High",
  AdvancedLow = "Advanced Low",
  AdvancedMid = "Advanced Mid",
  AdvancedHigh = "Advanced High",
  Proficient = "Proficient",
}

// the score ranges from 0 to 10
// enum describes the lower ends (starts) of each category ranges
// E.g., full range: [0 - 4.4] = Dependent, lower end (start): 0
// If needs to be updated, also update it in WEB and API
export enum SpeakingLevelScoreRangesStarts {
  Dependent = 0, // [0 - 4.4]
  IntermediateLow = 4.5, // [4.5 - 4.9]
  IntermediateMid = 5.0, // [5.0 - 5.9] (equivalent of B1)
  IntermediateHigh = 6.0, // [6.0 - 6.9]
  AdvancedLow = 7.0, // [7.0 - 7.9] (equivalent of B2)
  AdvancedMid = 8.0, // [8.0 - 8.9] (equivalent of C1)
  AdvancedHigh = 9.0, // [9.0 - 9.2] (equivalent of C2)
  Proficient = 9.3, // [9.3 - 10]
}

export const SpeakingLevelScoreRangesStartsValues = Object.values(
  SpeakingLevelScoreRangesStarts,
).filter((value) => typeof value === "number");

// If needs to be updated, also update it in WEB and API
export const getSpeakingLevelCategory = (score: number) => {
  if (score >= SpeakingLevelScoreRangesStarts.Proficient) return SpeakingLevelCategories.Proficient;
  if (score >= SpeakingLevelScoreRangesStarts.AdvancedHigh)
    return SpeakingLevelCategories.AdvancedHigh;
  if (score >= SpeakingLevelScoreRangesStarts.AdvancedMid)
    return SpeakingLevelCategories.AdvancedMid;
  if (score >= SpeakingLevelScoreRangesStarts.AdvancedLow)
    return SpeakingLevelCategories.AdvancedLow;
  if (score >= SpeakingLevelScoreRangesStarts.IntermediateHigh)
    return SpeakingLevelCategories.IntermediateHigh;
  if (score >= SpeakingLevelScoreRangesStarts.IntermediateMid)
    return SpeakingLevelCategories.IntermediateMid;
  if (score >= SpeakingLevelScoreRangesStarts.IntermediateLow)
    return SpeakingLevelCategories.IntermediateLow;
  return SpeakingLevelCategories.Dependent;
};
