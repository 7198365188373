import { useMutation, useQuery } from "@apollo/client";
import { type ApolloError } from "@apollo/client/errors";
import { useSnackbar } from "notistack";
import { append } from "ramda";
import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router";

import { LESSON_NAMES } from "src/helpers/const";

import { CREATE_SECTION, DELETE_SECTION, EDIT_SECTION } from "../gql/mutations/section";
import { QUERY_LESSON_SECTIONS } from "../gql/queries/lesson";

import { Element, ElementKind, type SectionModel } from "../types/graphql";

import { isEqualWith } from "lodash";
import {
  add,
  enhanceMeta,
  enhanceMetaOnChild,
  insertElementAt,
  move,
  remove,
  update,
} from "../helpers/sortable";

/**
 * Helpers
 */
const customizer = (objValue: Element, othValue: Element, key: string, object: Element) => {
  // Ignore changes in paragraph value
  if (object && object.kind === ElementKind.Paragraph && key === "value") {
    return true;
  }
  if (objValue === null && othValue === null) {
    return true;
  }
};

/**
 * Types
 */
interface Props {
  children: JSX.Element | JSX.Element[];
}

enum ActionType {
  AddSection = "ADD_SECTION",
  DeleteSection = "DELETE_SECTION",
  MoveSection = "MOVE_SECTION",
  AddElement = "ADD_ELEMENT",
  EditElement = "EDIT_ELEMENT",
  DeleteElement = "DELETE_ELEMENT",
  MoveElement = "MOVE_ELEMENT",
  InsertElement = "INSERT_ELEMENT",
  EnhanceElementMeta = "ENHANCE_ELEMENT_META",
  EnhanceElementMetaForChild = "ENHANCE_ELEMENT_META_FOR_CHILD",
  Initial = "INITIAL",
}

interface HistoryEntry {
  sections: SectionModel[];
  action: ActionType;
  sectionId: string;
}

interface ContextType {
  title?: string;
  lessonId?: string;
  sections: SectionModel[];
  loading: boolean;
  error?: ApolloError;
  onCreateSection: () => Promise<void>;
  onSaveSection: (sectionId: string) => Promise<void>;
  onDeleteSection: (sectionId: string) => Promise<void>;
  onAddElement: (sectionId: string, item: unknown) => void;
  onEditElement: (sectionId: string, index: number, value: string) => void;
  onDeleteElement: (sectionId: string, index: number) => void;
  onEnhanceElementMeta: (sectionId: string, index: number, key: string, value: string) => void;
  onMoveElement: (dragIndex: number, hoverIndex: number, sectionId?: string) => void;
  onInsertElement: (sectionId: string, index: number, item: unknown) => void;
  onMoveSection: (sectionId: string, newIndex: number) => void;
  onEnhanceElementMetaForChild: (
    sectionId: string,
    parentIndex: number,
    childIndex: number,
    key: string,
    value: string,
  ) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}

/**
 * Context
 */
const LessonContext: React.Context<ContextType> = React.createContext({} as ContextType);

/**
 * Constants
 */
const HISTORY_LIMIT = 20;

/**
 * Provider
 */
export const LessonProvider: React.FC<Props> = ({ children }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { lessonId } = useParams<Record<string, string>>();

  const [title, setTitle] = useState<string | undefined>(undefined);
  const [sections, setSections] = useState<SectionModel[]>([]);
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const historyIndex = useRef<number>(-1);
  // Used to prevent adding undo actions to the history
  const isUndoingRef = useRef<boolean>(false);

  const canUndo = historyIndex.current > 0;
  const canRedo = historyIndex.current < history.length - 1;

  const updateHistory = useCallback(
    (action: ActionType, sectionId: string, newSections: SectionModel[]) => {
      if (!isUndoingRef.current) {
        setHistory((prev) => {
          const newHistory = [...prev, { sections: [...newSections], action, sectionId }];
          return newHistory.length > HISTORY_LIMIT ? newHistory.slice(1) : newHistory;
        });
        if (historyIndex.current < HISTORY_LIMIT) {
          historyIndex.current += 1;
        }
      }
    },
    [],
  );

  const revert = async (previousEntry: HistoryEntry, nextEntry: HistoryEntry) => {
    isUndoingRef.current = true;
    const { sections } = nextEntry;
    const { sectionId: previousSectionId, action: previousAction } = previousEntry;

    if (previousAction === ActionType.AddSection) {
      try {
        await deleteSection({ variables: { id: previousSectionId } });
        enqueueSnackbar("Section successfully deleted", { variant: "success" });
        setSections(sections);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    } else if (previousAction === ActionType.DeleteSection) {
      try {
        const section = sections.find((section: SectionModel) => section.id === previousSectionId);
        if (section) {
          await restoreSection(section);
        }
        setSections(sections);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    } else {
      setSections(sections);
    }
    isUndoingRef.current = false;
  };

  const undo = async () => {
    if (!canUndo) {
      return;
    }
    const previousEntry = history[historyIndex.current];
    historyIndex.current -= 1;

    const nextEntry = history[historyIndex.current];
    await revert(previousEntry, nextEntry);
  };

  const redo = async () => {
    if (!canRedo) {
      return;
    }
    const previousEntry = history[historyIndex.current];
    historyIndex.current += 1;
    const nextEntry = history[historyIndex.current];
    await revert(previousEntry, nextEntry);
  };

  const { loading, error } = useQuery(QUERY_LESSON_SECTIONS, {
    fetchPolicy: "network-only",
    variables: {
      id: lessonId,
    },
    onCompleted: (data) => {
      if (data?.adminGetLesson?.sections) {
        const title = data?.adminGetLesson?.title ?? LESSON_NAMES.get(data?.adminGetLesson?.kind);
        setTitle(title);
        setSections(data?.adminGetLesson?.sections);
        setHistory([
          { sections: data?.adminGetLesson?.sections, action: ActionType.Initial, sectionId: "" },
        ]);
        historyIndex.current = 0;
      }
    },
  });

  const [editSection] = useMutation(EDIT_SECTION);
  const [createSection] = useMutation(CREATE_SECTION);
  const [deleteSection] = useMutation(DELETE_SECTION);

  const onCreateSection = useCallback(async () => {
    const variables = {
      input: {
        lessonID: lessonId,
        order: sections.length,
      },
    };

    try {
      const { data } = await createSection({ variables });
      const newSections = append(data?.adminCreateSection, sections);
      setSections(newSections);
      updateHistory(ActionType.AddSection, data?.adminCreateSection.id, newSections);
      enqueueSnackbar("Section successfully created", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [createSection, enqueueSnackbar, lessonId, sections, updateHistory]);

  const onSaveSection = useCallback(
    async (sectionId: string) => {
      const order = sections.findIndex((section) => section.id === sectionId);

      const variables = {
        id: sectionId,
        input: {
          order,
          elements: sections[order]?.elements,
        },
      };

      try {
        await editSection({ variables });
        enqueueSnackbar("Section successfully saved", { variant: "success" });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [editSection, enqueueSnackbar, sections],
  );

  const onMoveSection = useCallback(
    async (sectionId: string, newIndex: number) => {
      try {
        const currentIndex = sections.findIndex((s) => s.id === sectionId);
        const newSections = [...sections];

        const [movedSection] = newSections.splice(currentIndex, 1);

        newSections.splice(newIndex, 0, movedSection);

        const updatedSections = newSections.map((section, index) => ({
          ...section,
          order: index,
        }));

        const sectionsToEdit = updatedSections
          .filter((section, index) => sections[index].order !== section.order)
          .map((section) => ({
            id: section.id,
            input: { order: section.order },
          }));

        await Promise.all(
          sectionsToEdit.map(({ id, input }) =>
            editSection({
              variables: {
                id,
                input,
              },
            }),
          ),
        );

        setSections(updatedSections);
        updateHistory(ActionType.MoveElement, sectionId, updatedSections);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [editSection, sections, updateHistory, enqueueSnackbar],
  );

  const onDeleteSection = useCallback(
    async (sectionId: string) => {
      const variables = {
        id: sectionId,
      };

      try {
        await deleteSection({ variables });
        const newSections = sections.filter((sectionModel) => sectionModel.id !== sectionId);
        setSections(newSections);
        updateHistory(ActionType.DeleteSection, sectionId, newSections);
        enqueueSnackbar("Section successfully deleted", { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [deleteSection, enqueueSnackbar, sections, updateHistory],
  );

  const restoreSection = async (section: SectionModel) => {
    try {
      const response = await createSection({
        variables: { input: { lessonID: lessonId, order: section.order } },
      });
      //Add back the elements
      await editSection({
        variables: {
          id: response.data?.adminCreateSection.id,
          input: {
            order: section.order,
            elements: section.elements,
          },
        },
      });
      enqueueSnackbar("Section successfully restored", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const onAddElement = useCallback(
    (sectionId: string, item: unknown) => {
      const newSections = add(sectionId, sections, item);
      setSections(newSections);
      updateHistory(ActionType.AddElement, sectionId, newSections);
    },
    [sections, updateHistory],
  );

  const onDeleteElement = useCallback(
    (sectionId: string, index: number) => {
      const newSections = remove(sectionId, sections, index);
      setSections(newSections);
      updateHistory(ActionType.DeleteElement, sectionId, newSections);
    },
    [sections, updateHistory],
  );

  const onEditElement = useCallback(
    (sectionId: string, index: number, value: unknown) => {
      setSections((prevSections) => {
        const newSections = update(sectionId, prevSections, index, value);

        const section = prevSections.find((section) => section.id === sectionId);
        const newSection = newSections.find((section) => section.id === sectionId);
        const editedElement = section?.elements ? section?.elements[index] : null;

        if (editedElement) {
          const isParagraph =
            section?.elements && section?.elements[index]?.kind === ElementKind.Paragraph;
          if (isParagraph) {
            return newSections;
          }
          const isGrid = section?.elements && section?.elements[index]?.kind === ElementKind.Grid;
          if (isGrid) {
            const newEditedElement = newSection?.elements ? newSection?.elements[index] : null;
            const paragraphInGridChanges =
              editedElement &&
              newEditedElement &&
              isEqualWith(editedElement, newEditedElement, customizer);
            if (paragraphInGridChanges) {
              return newSections;
            }
          }
        }

        updateHistory(ActionType.EditElement, sectionId, newSections);
        return newSections;
      });
    },
    [updateHistory],
  );

  const onMoveElement = useCallback(
    (dragIndex: number, hoverIndex: number, sectionId?: string) => {
      if (sectionId && dragIndex !== hoverIndex) {
        const newSections = move(sectionId, sections, dragIndex, hoverIndex);
        setSections(newSections);
        updateHistory(ActionType.MoveElement, sectionId, newSections);
      }
    },
    [sections, updateHistory],
  );

  const onInsertElement = useCallback(
    (sectionId: string, index: number, item: unknown) => {
      const newSections = insertElementAt(sectionId, sections, index, item);
      setSections(newSections);
      updateHistory(ActionType.InsertElement, sectionId, newSections);
    },
    [sections, updateHistory],
  );

  const onEnhanceElementMeta = useCallback(
    (sectionId: string, index: number, key: string, value: unknown) => {
      const newSections = enhanceMeta(sectionId, sections, index, key, value);
      setSections(newSections);
      updateHistory(ActionType.EnhanceElementMeta, sectionId, newSections);
    },
    [sections, updateHistory],
  );

  const onEnhanceElementMetaForChild = useCallback(
    (sectionId: string, parentIndex: number, childIndex: number, key: string, value: unknown) => {
      const newSections = enhanceMetaOnChild(
        sectionId,
        sections,
        parentIndex,
        childIndex,
        key,
        value,
      );
      setSections(newSections);
      updateHistory(ActionType.EnhanceElementMetaForChild, sectionId, newSections);
    },
    [sections, updateHistory],
  );

  return (
    <LessonContext.Provider
      value={{
        lessonId,
        title,
        sections,
        loading,
        error,
        onCreateSection,
        onSaveSection,
        onDeleteSection,
        onAddElement,
        onEditElement,
        onDeleteElement,
        onMoveElement,
        onInsertElement,
        onEnhanceElementMeta,
        onEnhanceElementMetaForChild,
        onMoveSection,
        undo,
        redo,
        canUndo,
        canRedo,
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};

export default LessonContext;
