import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import {
  SpeakingLevelCategoriesText,
  SpeakingLevelScoreFormatter,
  getSpeakingLevelCategory,
} from "src/helpers/speakingLevel";
import {
  AssessmentResultModel,
  type UserModel,
  useGetUserAssessmentResultsQuery,
} from "src/types/graphql";
import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  user: UserModel;
}

const limit = 10;

const UserLanguageAssessmentCard: React.FC<Props> = ({ user }: Props) => {
  const credits = user.availableAssessmentCharges;

  const { enqueueSnackbar } = useSnackbar();

  const [offset, setOffset] = useState(0);
  const [results, setResults] = useState<AssessmentResultModel[]>([]);

  const { data, loading, fetchMore } = useGetUserAssessmentResultsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      userId: user.id,
      filters: {
        limit,
        offset,
      },
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onCompleted: (data) => {
      setResults((prevResults) =>
        prevResults.concat(
          (data?.getUserAssessmentResults?.items as AssessmentResultModel[]) ?? [],
        ),
      );
    },
  });

  const total = data?.getUserAssessmentResults?.total ?? 0;

  const nextPage = useCallback(() => {
    if (total > offset + limit) {
      setOffset((prevOffset) => prevOffset + limit);
      void fetchMore({
        variables: {
          filters: {
            limit,
            offset: offset + limit,
          },
        },
      });
    }
  }, [offset, total, fetchMore]);

  if (loading && offset === 0) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <CardHeader
        title={
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Speaking Level Assessment</Typography>
                </TableCell>
                <TableCell align="right">Test Credits remaining:</TableCell>
                <TableCell align="left">{credits}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
      />
      <CardContent>
        <Table>
          <TableBody>
            {results.map((assessmentResult) => {
              const date = format(new Date(assessmentResult.createdAt), "dd-MM-yyyy");
              const score = assessmentResult.halloScore ?? 0;
              const level = getSpeakingLevelCategory(score);
              const levelText = SpeakingLevelCategoriesText[level];

              return (
                <TableRow key={assessmentResult.id}>
                  <TableCell>{date}</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>{SpeakingLevelScoreFormatter.format(score)}</TableCell>
                  <TableCell>{levelText}</TableCell>
                  <TableCell align="right">
                    {!assessmentResult.isAddedByAdmin && (
                      <Link
                        href={`https://www.dashboard.hallo.ai/assessments/detail?assessment-id=${assessmentResult.assessmentId}`}
                        target="_blank"
                      >
                        View
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {total > limit + offset && (
          <Box mt={Spacing.xl}>
            <Button variant="text" endIcon={<ExpandMoreIcon />} onClick={nextPage}>
              Show more
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default UserLanguageAssessmentCard;
