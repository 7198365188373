import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { type UserModel } from "src/types/graphql";

/**
 * Helpers
 */
const getHoursAndMinutes = (minutes?: number | null): string => {
  return minutes ? `${Math.floor(minutes / 60)}h ${minutes % 60}m` : "0h 0m";
};

/**
 * Types
 */
interface Props {
  user: UserModel;
}

const UserPageDetailsMetricsCard: React.FC<Props> = ({ user }: Props) => {
  return (
    <Card>
      <CardHeader title="User Engagement" />

      <Divider />

      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Total Events</TableCell>
              <TableCell>{user.engagementMetrics?.attendanceMetrics?.totalEvents}</TableCell>
              <TableCell variant="head">Total time</TableCell>
              <TableCell>
                {getHoursAndMinutes(user.engagementMetrics?.attendanceMetrics?.totalDuration)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Events hosted</TableCell>
              <TableCell>{user.engagementMetrics?.attendanceMetrics?.totalHostedEvents}</TableCell>
              <TableCell variant="head">Hosted time</TableCell>
              <TableCell>
                {getHoursAndMinutes(user.engagementMetrics?.attendanceMetrics?.totalHostedDuration)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Total Attended</TableCell>
              <TableCell>
                {user.engagementMetrics?.attendanceMetrics?.totalParticipatedEvents}
              </TableCell>
              <TableCell variant="head">Attended time</TableCell>
              <TableCell>
                {getHoursAndMinutes(
                  user.engagementMetrics?.attendanceMetrics?.totalParticipatedDuration,
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Scheduled attended events</TableCell>
              <TableCell>
                {user.engagementMetrics?.attendanceMetrics?.totalParticipatedScheduledEvents}
              </TableCell>
              <TableCell variant="head">Hosted participants</TableCell>
              <TableCell>{user.engagementMetrics?.totalHostedParticipants}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Nonscheduled attended events</TableCell>
              <TableCell>
                {user.engagementMetrics?.attendanceMetrics?.totalParticipatedNonScheduledEvents}
              </TableCell>
              <TableCell variant="head">Scheduled events RSVPs</TableCell>
              <TableCell>{user.engagementMetrics?.totalRsvps}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Articles published</TableCell>
              <TableCell>{user.engagementMetrics?.publishedArticles}</TableCell>
              <TableCell variant="head">Attendance ratio</TableCell>
              <TableCell>
                {Math.round((user.engagementMetrics?.attendanceRatio ?? 0) * 100)}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UserPageDetailsMetricsCard;
